.categories {
  .card {
    border-radius: .5rem;
    position: relative;
    height: 150px;
    overflow: hidden;

    &-img {
      object-fit: cover;
      object-position: center center;
    }

    &-body {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &-title {
      color: #fff;
    }
  }
}
.footer {
  background: $lightBgColor;

  @include responsive(atSmall) {
    text-align: center;
  }

  &__links {
    a {
      display: block;
      margin-bottom: 0.5rem;
    }
  }

  .fa-google-play,
  .fa-app-store {
    font-size: 2rem;
    color: $primary;
  }

  .sm-icons {
    margin-top: 3rem !important;

    .fas,
    .fab {
      font-size: 1.5rem;
    }

    .fa-facebook-f {
      color: #3b5998;
    }

    .fa-twitter {
      color: #00acee;
    }

    .fa-instagram {
      color: #e1306c;
    }

    .fa-youtube {
      color: #ff0000;
    }

    .fa-envelope {
      color: #998888;
    }
  }
}
.single-deal {
  .swiper {
    img {
      height: 400px;
    }
  }

  &__content {
    p {
      margin-top: 1rem;
    }
  }

  .ratings {
    h4 {
      font-size: 2.5rem;
    }
  }

  .reviews {
    .review {
      margin-bottom: 1.5rem;

      &-header {
        display: flex;

        &__avatar {
          margin-right: 1rem;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
          }
        }
      }

      &-footer {
        &__rating {
          display: flex;
        }
      }
    }
  }

  .deals {
    max-height: 1700px !important;
    overflow: auto;

    .deal {
      img {
        height: 150px;
      }
    }
  }
}
html,
body {
  overflow-x: hidden;
}

.modal.open {
  body {
    overflow-x: hidden;
  }
}

body {
  font-family: 'Open Sans'sans-serif;
  color: #333;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500 !important;
}

img {
  width: 100%;
}

section {
  .section-title {
    font-size: 1.5rem;
    text-align: center;

    @include responsive(atMedium) {
      font-size: 1.75rem !important;
      text-align: left;
    }
  }

  .section-subtitle {
    font-size: 1.25rem;
    text-align: center;

    @include responsive(atMedium) {
      font-size: 1.5rem !important;
      text-align: left;
    }
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .66);
  display: flex;
  justify-content: center;
  align-items: center;
}

i.fas.fa-star,
i.fas.fa-star-half-alt,
i.far.fa-star {
  color: #ffb400;
}

.fa-google-play,
.fa-app-store {
  font-size: 1.5rem;
  color: $primary;
}

.app-download {
  display: flex;
  justify-content: start;
  align-items: center;

  &__icon {
    font-size: 1.5rem !important;
    color: #fff;
    margin-right: 1rem;
  }

  &__text {
    color: #fff;
    display: flex;
    flex-direction: column;
    text-align: left;

    &-title {
      // font-size: .75rem;
      font-weight: 100;
    }

    &-subtitle {
      font-weight: 500;
    }
  }
}
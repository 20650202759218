.search-overlay {
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, .95);
  visibility: hidden;
  opacity: 0;
  transform: scale(1.09);
  transition: opacity .7s, transform .7s, visibility .7s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &--active {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }

  &__close {
    position: absolute;
    top: 2rem;
    left: 2rem;
    font-size: 2.5rem;
    color: $primary;
    cursor: pointer;
  }

  &__content {
    h2 {
      font-size: 2rem;

      @include responsive(atMedium) {
        font-size: 2.75rem;
      }
    }

    .search-overlay__search-input {
      .input-group {
        .form-control {
          border-radius: 50rem;
          width: 75%;
          padding-left: 1.5rem;

          @include responsive(atMedium) {
            width: 400px;
            height: 4rem;
          }
        }
      }
    }
  }
}
// Download app buttons
button.app-download {
  display: flex;
  justify-content: center;
  align-items: center;

  @include responsive(atSmall) {
    width: 50%;
  }

  img {
    width: 1.25rem;
    margin-right: 1rem;
  }

  i {
    font-size: 1.5rem;
    color: #fff;
    margin-right: 1rem;
  }
}
// Bootstrap reset
.text-primary {
  color: $primary !important;
}

.btn-primary {
  background: $primary !important;
}

// Select2
.select2 {
  width: 250px !important;
  height: 55px;

  &-selection {
    width: 100% !important;
    height: 100% !important;
    border: none !important;
    background-color: $lightBgColor !important;

    &[aria-expanded='true'] {
      border: none !important;


      .select2-selection__arrow {
        transform: rotate(90deg) !important;
        top: 25% !important;
      }
    }

    &__rendered {
      font-size: 1.5rem;
      padding-left: 10px !important;
      width: 100%;
      height: 100%;
      line-height: 55px !important;
      font-weight: bold;

      @include responsive(atSmall) {
        font-size: 1.33rem;
      }
    }

    &__arrow {
      top: 50% !important;
      transform: translateY(-50%) !important;
      right: .5rem !important;
      width: 25px !important;
      transition: transform 0.3s ease-in-out, top .3s ease-in-out;

      b {
        display: none;
      }

      img {
        display: inline-block;
        width: 100%;
      }
    }
  }

  &-container {
    border: none !important;
  }
}

span.select2-search {
  padding: 1rem .5rem !important;

  input.select2-search__field {
    &:focus {
      outline: none;
    }
  }
}

span.select2-results {
  padding: 0.5rem !important;
}
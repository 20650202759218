.modal {
  overflow-y: auto;

  .close {
    span {
      font-size: 2rem;
    }
  }

  .qr-code {
    height: 250px;

    img {
      height: 100%;
      object-fit: contain;
    }
  }

  .fa-apple,
  .fa-google-play {
    font-size: 1.25rem;
  }

  .fa-google-play {
    color: #48ff48;
  }

  // social login
  .social-login {
    button {
      border-radius: 50px;
      // padding: 10px 20px;
      color: #fff;
    }

    .fb-btn {
      transition: all 0.3s ease-in-out;
      background: #3b5998 !important;

      &:hover {
        background: #2d4373 !important;
      }
    }

    .google-btn {
      transition: all 0.3s ease-in-out;
      background: #dd4b39 !important;

      &:hover {
        background: #c23321 !important;
      }
    }

    .twitter-btn {
      transition: all 0.3s ease-in-out;
      background: #55acee !important;

      &:hover {
        background: #2d8dc7 !important;
      }
    }
  }

  .form-control-lg {
    border: 1px solid $primary !important;

    &:focus {
      border: 1px solid $primary !important;
    }

    &:placeholder {
      font-size: 1rem !important;
    }
  }

  .select2 {
    &-selection {
      border: 1px solid $primary !important;

      &__rendered {
        font-weight: normal !important;
        font-size: 1rem !important;
        text-align: left !important;
      }
    }
  }
}

#modalContactForm {
  .form-control-lg {
    width: 100%;
    border: 1px solid $primary;

    &:placeholder {
      font-size: 1rem !important;
    }

    &:focus {
      border: 1px solid $primary !important;
    }
  }

  textarea {
    border: 1px solid $primary;
    width: 100%;

    &:placeholder {
      font-size: 1rem !important;
    }

    &:focus {
      border: 1px solid $primary !important;
    }
  }
}

.modal-open .modal {
  overflow-y: auto;
}
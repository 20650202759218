.deals {

  img {
    border-radius: .5rem;
    height: 225px;
  }

  .deal {
    .popular-deal {
      line-height: 1.4;
    }
  }
}
.hero {
  background: $lightBgColor;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    @include responsive(atSmall) {
      font-size: 2rem;
    }
  }

  #searchForDeals {
    input {
      width: 50%;
      display: block;
      margin: 1rem auto 0 auto;
      height: 3rem;
      border-radius: 50rem;
      padding: 0.5rem 1.5rem;
      height: 3rem;
      display: block;
      margin: 1rem auto 3rem auto;
      border-radius: 25rem;
      padding: 0.5rem 1.5rem;
      width: 75%;
      transition: all 0.3s ease-in-out;
      text-align: center;
    }
  }
}